/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./UserLoginCss.css";
import { toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import useLoginNew from "../hooks/useLoginNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "../ClientPanel/UserLogin2.css";

export default function UserLogin2() {
  const [uid, setUid] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const { mutate: loginNew, isLoading: isLoggingIn } = useLoginNew();

  async function login(e) {
    e.preventDefault();
    let acc_type = "user";

    let payload = { acc_type: acc_type, code: code, password: password };

    if (code === "" || password === "") {
      return toast.error("Please fill in all fields");
    }

    loginNew(payload, {
      onSuccess: (response) => {
        console.log(response);
        if (response) {
          let registerData = response.data.register.data;
          sessionStorage.setItem("isUserLoggedIn", "true");
          localStorage.setItem("token", response.data.token);
          sessionStorage.setItem("NAME", response.data.register.data.name);
          sessionStorage.setItem("CODE", response.data.register.data.code);
          sessionStorage.setItem("UUID", response.data.register.data.id);
          sessionStorage.setItem(
            "DATA",
            JSON.stringify(response.data.register)
          );
          localStorage.setItem("STATUS", response.data.register.data.status);
          sessionStorage.setItem("status", response.data.register.data.status);
          sessionStorage.setItem(
            "sessionCommission",
            registerData.session_commission
          );
          sessionStorage.setItem(
            "matchCommission",
            registerData.match_commission
          );
          sessionStorage.setItem("password", password);
          toast.success("Client Login Successful", {
            duration: 5000, // Display for 5 seconds
          });
          window.location.href = "/welcome";
        } else {
          toast.error(response?.message);
        }
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    });
  }

  return (
    <>
      <div>
        {/* <Toaster position="top-right" reverseOrder={false} /> */}

        <div
          className="container main-background justify-content-center align-items-center d-flex"
          id="loginbackground-2"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4 mx-auto">
                <div>
                  <div className="card-header align-items-center d-flex flex-column border-bottom-0 pb-4">
                    <b className="MuiTypography-root MuiTypography-h1 txtcolor">
                      <img
                        src={"	https://jmd15.com/assets/images/black_logo.avif"}
                        alt="logo"
                        className="img-fluid"
                        style={{ width: "150px" }}
                      />
                    </b>
                  </div>
                  <div
                    className="container pt-4"
                    style={{ backgroundColor: "#0092ad", borderRadius: "5px" }}
                  >
                    <div className="card-body pl-3 pr-3">
                      <form className="BetPlayer-login-form login-form">
                        <h1 id="password1122" style={{ color: "white" }}>
                          User Name
                        </h1>
                        <span className="input-group mb-3">
                          <span
                            className="input-group-text fw-bold"
                            style={{
                              height: "45px",
                              backgroundColor: "white",
                              border: "1px solid #ced4da",
                              borderRightColor: "transparent",
                            }}
                          >
                            C
                          </span>
                          <span className="form-floating mb-1">
                            <input
                              className="form-control client-code-input"
                              name="uid"
                              id="form-control11"
                              onChange={(e) => {
                                setCode("C" + e.target.value);
                                setUid("C" + e.target.value);
                              }}
                              autoFocus
                              placeholder="Client Code"
                              style={{
                                borderLeftColor: "transparent",
                                height: "45px",
                              }}
                            />
                          </span>
                        </span>
                        <h1 id="password1122" style={{ color: "white" }}>
                          Password
                        </h1>
                        <div className="form-floating mb-1">
                          <input
                            type="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            id="form-control12"
                            placeholder="Password"
                            style={{
                              height: "45px",
                            }}
                          />
                        </div>

                        <div className="d-grid">
                          <button
                            onClick={login}
                            type="button"
                            className="btn  btn-block NewColor"
                            id="loginmainbutton"
                          >
                            {isLoggingIn ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              "LOGIN"
                            )}
                          </button>
                        </div>

                        <div className="col-12">
                          <center
                            className="mt-2 pb-4"
                            style={{ color: "white" }}
                          >
                            All about for online game zone © 2024 jmd10.com
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
