import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";

const useSocketCasinoData = (gameName) => {
  let userId = sessionStorage.getItem("UUID");

  const [data, setData] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    if (gameName === "") {
      return;
    }

    socketRef.current = io("https://api.jmd10.com");

    const handleConnect = () => {
      console.log("Connected to server");

      socketRef.current.emit("casino_data", {
        userId: userId,
        gameName: gameName,
      });

      const handleMatchData = (matchData) => {
        setData(matchData);
      };

      socketRef.current.on(`casino_${gameName}_${userId}`, handleMatchData);

      return () => {
        socketRef.current.off(`casino_${gameName}_${userId}`, handleMatchData);
      };
    };

    const handleError = (error) => {
      console.error("Socket error: ", error);
    };

    socketRef.current.on("connect", handleConnect);
    socketRef.current.on("error", handleError);

    return () => {
      socketRef.current.disconnect();
      socketRef.current.off("connect", handleConnect);
      socketRef.current.off("error", handleError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameName]);

  return data;
};

export default useSocketCasinoData;
